import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Icon from '../../components/Icon';

const ScienceStarters = () => (
  <Layout title="Science Starters" className="science-starters">
    <div className="hero-container science">
      <div className="hero-container__gradient bg-gradient-purple"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb active">Science Starters</li>
        </ul>
        <h1 className="page-title">Science Starters</h1>
        <Image filename="hero-getting-started.png" className="hero-image" />
      </div>
    </div>
    {/* Getting Started */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>Getting Started </h2>
            <h4>
              Embrace the power of innovation to solve problems and achieve
              real-world results in students’ lives with these hands-on
              resources. Kickstart immersive and open-ended learning experiences
              with flexible resources designed to inspire the next generation of
              scientists.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Hands on Activities */}
    <Section className="mb-3">
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="image-activities-promo-1.jpg" />
          </Column>
          <Column size={5} className="pl-1 pb-4">
            <Image filename="icon-beakers.svg" />
            <h3 className="text-38 mt-1 mb-0">Hands-On Activities</h3>
            <p className="text-24">
              Inspire curiosity and problem solving through standards-aligned
              activities that empower students to become the solution seekers of
              tomorrow.
            </p>
            <Link
              to="/science-starters/lesson-plans"
              className="cta__link no-arrow bg-gradient-purple"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Science at Work */}
    <Section className="mb-3">
      <Container>
        <Row>
          <Column size={5} className="ml-6 pb-6">
            <Image filename="icon-desk.svg" />
            <h3 className="text-38 mt-1 mb-0">Science at Work</h3>
            <p className="text-24">
              Discover what a day in the life of a 3M Scientist is like as
              students step inside a 3M laboratory.
            </p>
            <Link
              to="/science-starters/science-at-work"
              className="cta__link no-arrow bg-gradient-purple"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
          <Column size={6} className="pl-2">
            <Image filename="image-science-at-work-promo.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Science at Home */}
    <Section className="mb-3">
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="image-science-at-home-promo.jpg" />
          </Column>
          <Column size={5} className="pl-1 pb-6">
            <Image filename="icon-glasses.svg" />
            <h3 className="text-38 mt-1 mb-0">Science at Home</h3>
            <p className="text-24">
              Bring to life core scientific principles with simple, hands-on
              experiments that utilize common household items.
            </p>
            <Link
              to="/science-starters/science-at-home"
              className="cta__link no-arrow bg-gradient-purple"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Virtual Field Trips */}
    <Section className="mb-3">
      <Container>
        <Row>
          <Column size={5} className="ml-6 pb-6">
            <Image filename="icon-backpack.svg" />
            <h3 className="text-38 mt-1 mb-0">Virtual Field Trips</h3>
            <p className="text-24">
              Transport students to extraordinary locations around the world and
              introduce them to real 3M innovators and professionals.{' '}
            </p>
            <Link
              to="/science-starters/virtual-field-trips"
              className="cta__link no-arrow bg-gradient-purple"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
          <Column size={6} className="pl-2">
            <Image filename="image-virtual-field-trips-promo.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ScienceStarters;
